import mint from './mint/en'

export default {
  mint,
  unit: {
    unit1: 'Pcs',
    unit2: 'Display',
    unit3: 'times',
    unit4: 'Coin',
    unit5: 'pcs',
    unit6: 'Holy sh** Box',
    unit7: 'Please bind Holy sh** Number in DASHBOARD first',
    unit8: 'Coming Soon',
    unit9:
      'Holy sh** has detected that TokenPocket is not installed on your browser  ',
    unit10:
      'TokenPocket is widely used by many blockchain applications to keep your information secure, please install TokenPocket first  ',
    unit11: 'Install TokenPocket  ',
    unit12: 'Do you have TokenPocket installed  ？',
    unit13: 'Please bind ',
    unit14: 'Have You Installed Metamask？',
    unit15:
      'Holy sh** has detected that your browser does not haveMetamask installed.',
    unit16:
      'Metamask is widely used by many blockchain applications to ensure the security of your information.',
    unit17: 'Install MetaMask',
    unit18: 'Please bind your wallect first!',
    unit19: 'Not enough balance!',
    unit20: 'This round is sold out!',
    unit21: 'Copy successfully',
    unit22: 'Network Error',
    unit23: 'Please change the network to Arbitrum',
    unit24: 'Arbitrum One balance insufficient',
  },
  MetaMaskTips: 'Make sure your browser has the MetaMask plug-in installed',
  button: {
    button1: 'Synthesis',
    button2: 'Upload',
    button3: 'Gathering',
    button4: 'Approve',
    button5: 'still synthesis',
    button6: 'Hang on the HolyWall',
    button7: 'Open',
    button8: 'Detail',
    button9: 'Hang on the HolyWall',
    button10: 'Check out the personal center',
    button11: 'Buy Holy sh** Box',
    button12: 'Please try it again.',
    button13: 'Buy another one',
  },
  // 头部菜单
  menu: {
    nav01: 'Holy sh** Box',
    nav02: 'Holy Wall',
    nav03: 'Holy Bed',
    nav04: 'Holy Shop',
    nav05: 'Connect wallet ',
    nav06: 'Home',
    nav07: 'Personal center',
    nav08: 'Privacy policy',
    nav09: 'DASHBOARD',
    home:'Home',
    makelove:'Holy Mess',
    mintMeh:'Mint a meh',
  },
  index: {
    firstPartTitle: 'NFT Revelation Card',
    firstPartTxt1:
      'Holy sh** REVELATION CARD is called HRC for short, and is also called the Revelation Card.',
    firstPartTxt2:
      'It is a crypto-trendy cultural collection released by Holy sh** designer brands，17280 Revelation Cards will be generated in all.',
    firstPartTxt3:
      'The first release of <span style="color:#F8C10A">432</span> Revelation Cards  are illustrated with hidden and mysterious meanings.',
    firstPartTxt4:
      'Each image on the Revelation Card will generate <span style="color:#F8C10A">40</span> NFT Revelation Cards via ERC721 crypto technology.',
    firstPartTxt5: '~ White×27 ~ Pink×9 ~ Gold×3 ~ Rainbow×1 ~',
    firstPartBtn: 'Buy a Holy sh** Box',
    Part2Title: 'Karma & Quality',
    Part2text1:
      'Each Revelation Card has a karma. karma represents the power value of a Holy sh** REVELATION CARD， The higher the level of the character the higher the karma value is.',
    Part2text2:
      'For example, the role of God has a higher karma than Fish. Also the texture and colour of the Holy sh** REVELATION CARD will affect the karma value.',
    Part2text3: 'Base',
    Part3Title: 'Here are 3  reasons for you to keep a Revelation Card.',
    Part3text1: 'Gain worship reward',
    Part3text2: 'Gain benefits from authorized Holy sh** derivatives sale',
    Part3text3: 'Gain airdrops of Holy sh** derivatives ',
    Part3text4: 'Holy sh** Store privilege',
    Part3text5: 'Holy sh**verse  privilege',
    Part3text6:
      "Each Revelation Card you keep has a rich content. It's said that extraordinary revelatory power was shown to the people who saw it. So you can upload your cards on HolyWall to share with your friends. Invite your friends to worship them can bring you SHT as a token reward.",
    Part3text7:
      'If the item sold contains your inspiration card, you and the other owners will receive a licensing bonus, and 15% of the sales profit will be given to the 40 owners of the Inspiration card for each item sold around the entity.',
    Part3text8:
      'You have a chance to receive Holy sh** derivatives airdrops by keeping at least one NFT Revelation Card. Collectors who had his Revelation Cards on HolyWall will firstly receive airdrops when new products are released in the Holy sh** Store. Imagine how rich the airdrops are! Including but not limited to NFT collections, fashionable clothes, bags and other lifestyle fashionable accessories.',
    Part3text9:
      'Holy sh** Store only sells Holy sh** Revelation derivatives. It contains fashionable clothes, accessories, supplies and all kinds of other pop toys. All designs are on the theme of revelation. As long as you have one card, you can enjoy VIP treatment.',
    Part3text10: 'Privilege I: worldwide  shipping free',
    Part3text11: 'Privilege II: 10% off for all derivatives',
    Part3text12: 'Privilege III: Purchase VIP exclusive derivatives',
    Part3text13:
      "Holy sh**vers is the first pop fashionable social platform that contains social life, video, gaming, online business and fashion. It's Holy sh**ers online spiritual home. Everything is possible here. Only those who kept Holy sh** REVELATION CARDs will be permitted to join the community and  invite people to join too.",
    Part3text14: 'Privilege II: 10% for all derivatives',
    Part3text15: 'Privilege II: 10% for all derivatives',
    Part3text16: 'Privilege II: 10% for all derivatives',
    Part3text17: 'Privilege II: 10% for all derivatives',
    Part4Title: 'Holy sh** Number',
    Part4text1:
      `Holy sh** Number is your unique ID in Holy sh** World; you must have a Holy sh** Number before activating your account and purchasing NFT revelation cards. You can also pay for a Holy sh** Replacement Number.`,
    Part4Btn1: 'Get a Holy sh** Number',
    Part5Title: 'Roadmap of Holy sh**',
    Part5text1:
      'On June 16th, Globe Revelation Day, the first round of Holy sh** box officially launched',
    Part5text2:
      'HolyWall Open Day, the holy wall is opened, and the worship incentives are released',
    Part5text3_1:
      'HolyBed Open Day, the holy bed is opened, and the revelation card synthesis upgrade function is opened',
    Part5text3:
      'Holy sh** Revelation T, the first batch of trendy NFT-Shirts began to be sold, and airdrops to previous card holders began',
    Part5text4:
      'Holy sh** avatar airdrop and on sale',
    Part5text5:
      'Holy sh** FuckCovid-19 Event, the "Holy sh** FuckCovid-19" global public welfare activity starts',
    Part5text6:
      'Holy sh** SocialPlatform is launched for public beta, Holy sh** Metaverse is created',
    Part6Title: 'Holy sh** Story',
    Part6text1:
      'Holy sh** is a crypto trendy collections brand founded by several trendy artists who love the crypto industry.',
    Part6text2: `Every time we seek for the wisdom, we feel that we have been healed of life's ups and downs, 
    or inspired or empowered by them, We hope to bring these simple philosophies to collectors in a more trendy, street-level, 
    and grounded form, so even if it is just a flash of enlightenment, it is a manifestation of our brand value.`,
    Part6text3:
      `Holy sh** stands for Decentralized, Independent, Artistic, and Omni. 
      We combine crypto technology with trendy art and build a bridge of trust between virtual and reality; 
      our products should look good, playful,  wearable, and Practicality.`,
    Part6text4:
      `Holy sh**'s ideal is to use encryption technology combined with trendy art to create a fashionable avant-garde lifestyle so that every collector and user can get a small sublimation of body and soul; 
      This is our lifelong pursuit...`,
    Part7Title: `Getting Holy sh**'s lastest information`,
    Part7text1:
      'Input your email address and subscribe to the latest news of airdrops and sales.',
    Part7text2: 'Join Holy sh** community and communicate with global collectors.',
    Part7text3:
      'Upload your cards on HolyWall to share with your friends. Invite your friends to worship them can bring you SHT as a token reward.',
    Part7text4:
      'Join the Holy sh** community and communicate with collectors from all over the world',
    Part7text6:
      'Join the Holy sh** community and communicate with collectors from all over the world',
    Part7text7:
      'Join the Holy sh** community and communicate with collectors from all over the world',
    Part7text8:
      'Join the Holy sh** community and communicate with collectors from all over the world',
    Part7text9:
      'Join the Holy sh** community and communicate with collectors from all over the world',
    Part7text10: 'Send',
    mPart1text1:
      'Holy sh** REVELATION CARD is called HRC for short, you can call it Revelation Card. ',
    mPart1text2: `It is a crypto fashionable culture collection designed by Holy sh** designer. `,
    mPart1text3: '17280 Revelation Cards will be generated in all.',
    mPart1text4:
      ' The first round will release 432 Revelation Cards and each card is generated via crypto technology. ',
    mPart1text5: 'They respectively are:',
    mPart1text6:
      'Every Revelation Card has a karma. Karma stands for the power of a revelation.',
    mPart1text7:
      'Holders of Revelation Cards also have the image copyright of the cards. 40 image Holders will gain the authorization benefits which 15% profits of the derivatives sale will give to them.',
    mPart1text8:
      'Upload your Revelation Card on HolyWall will win you an airdrop chance.  The airdrops contain NFT collections, fashionable clothes, bags and other lifestyle fashionable accessories.',
    mPart1text9:
      'Only those who keep Holy sh** REVELATION CARDs are permitting to join the community and invite people to join too.',
    mPart1text10:
      `Holy sh** Number is your unique ID in the Holy sh**verse. 
      You must have a Holy sh** Number to activate your account and purchase the Revelation Card. 
      When the fourth round of sales starts, every collector will get an extra free Holy sh** Number that you can give to your friends.  
      Follow Holy sh** on Twitter and Discord for the first Holy sh** airdrop`,
    mPart1text11:
      'On June 18th, Globe Revelation Day, the first round of Holy sh** box officially launched',
    mPart1text12:
      'On August 18, the holy wall opened and the reward of worship began to be released',
    mPart1text13:
      'On August 28, the holy bed was opened and the upgrade function of Revelation brand synthesis was opened',
    mPart1text14:
      'On September 1, Holy sh** opened its doors and RevelationT made its debut',
    mPart1text15:
      'During the official release of Holy sh**, there will be a lot of surprises that are kept secret, please pay close attention to Holy sh**. One and official Twitter',
    mPart1text16: 'On December 21，Holy sh** App the official launch',
    Part1text17: 'Airdrop',
    Part1text18: 'Submission failed. Please enter the correct email address',
    Part1text19: 'Please enter the correct email address',
    Part1text20: 'Send success',
    Part1text21:
      'Before the launch of Holy sh** World social platform, we will conduct a round of avatar NFT asset release',
    Part1text22:
      'The "Holy sh** Style" avatars will be your personalized expression on the social platform, and will be highly recognizable and unique in the world.',
    Part1text23: 'A total of ',
    Part1text24: 'Holy sh** avatars will be released, of which ',
    Part1text25: 'Will be randomly dropped to some cardholders.',
    Part1text26: 'The rest will be subscribed at a specific price',
    Part1text27: '/each.',
    Part1text28:
      'If you hold a Holy sh** REVELATION CARD, you will not only get a free drop, but you will also be on the priority purchase white list.',
    Part1text29:
      'Holy sh** Number airdrop will start at 22:00 EST on September 21, 2021!',
    Part1text30: 'Have you connected the wallet?',
    Part1text31: 'Please bind your wallect first for the next step.',
    Part1text32: 'Worship Reward',
    Part1text33: 'Get a permanent airdrop',
    Part1text34: 'Holy sh** social gaming platform  privilege',
    Part1text35: 'Holy sh** World',
    Part1text36: '',
    Part1text37: 'It is a crypto entertainment social platform.',
    Part1text38:
      'Holy sh** World will introduce many popular NFT crypto asset programs and games.',
    Part1text39:
      'And through a variety of personalized features to allow various game player groups can easily communicate with each other.',
    Part1text40:
      'And you can open and experience the games directly through Holy sh** APP.',
    Part1text41:
      'The game assets of each game will be freely traded and rented on the platform.',
    Part1text42: `At the same time, any player or project owner can issue their own NFT at any time and anywhere based on Holy sh**'s intelligent NFT function.`,
    Part1text43: `Holy sh**'s revelation token is the governing NFT of Holy sh** World`,
    Part1text44: 'All collectors holding a Reveal NFT will have basic rights to vote and oversee the world',
    Part1text45: 'Rainbow level tokens have platform bonus rights and exclusive proposal rights',
    Part1text46:
      'The relevant revelation rights and bonuses will be available after the launch of Holy sh** World.',
    Part1text47: 'Please look forward to it!',
    Part1text48: 'Have you bound the Holy sh** Number yet?',
    Part1text49: 'To proceed to the next step, you need to bind your wallet address to the Holy sh** Number. We will do airdrops from time to time. You can also ask your friend to give you a bound Holy sh** Number',
    Part1text50: 'When you bind for the first time, we will give you an extra Holy sh** Number,',
    Part1text51: 'Bind',
    Part1text52: 'Enter Holy sh** Number',
  },
  buyBox: {
    Part1Title: 'Buy a Holy sh** Box',
    Part1text1: 'Round {msg}',
    Part1text2: 'Round {msgO}/{msgT} Sale',
    Part1text3: ' round sell',
    Part1text4: 'Current Price',
    Part1text5: 'Current Quantity',
    Part1text6: 'Start Time',
    Part1text7: 'End Time',
    Part1text8: 'Wait for sale',
    Part1text9: 'Buy ',
    Part1text10: 'This round is sold out!',
    Part1text11: 'Congrats! You got a Holy sh** Box!',
    Part1text12: 'You can open this Holy sh** Box in Dashboard!',
    Part1text13: "Let's see what's the surprise!",
    Part1text14: 'Go to check this Holy sh** Box in DashBoard ',
    Part2Title: 'About Holy sh** Box',
    Part2text1: 'All NFT Revelation Cards will be sold out in ',
    Part2text2: ' rounds. ',
    Part2text3: 'There are ',
    Part2text4: ' cards for sale in every round. The initial price is ',
    Part2text5: 'and the price will increase by ',
    Part2text6: 'Holy sh**CLUB will reserve only 432 pieces for airdrop and rewards.',
    Part3Title: 'Holy sh** Roles',
    Part3text1: 'The first 9 roles arrived on Earth.',
    Part3text2: 'a self-abasement and optimistic fat fish',
    Part3text3: 'forgetful, brainless, live in its own private universe',
    Part3text4: 'is nature to imitate human beings',
    Part3text5: 'simple-minded with elastic  thinking',
    Part3text6: 'lazy and timid, cowardly and crafty',
    Part3text7: 'sometimes grows out of hands ',
    Part3text8: 'stubborn, sensitive and raunchy',
    Part3text9: 'loser psychology',
    Part3text10: 'a combination of Alien and cat',
    Part3text11: 'cold, alert, turn against at any time',
    Part3text12: 'a butcher keeping in a state of outrage',
    Part3text13: 'never  living a normal life',
    Part3text14: 'tool man working as a  007 spy',
    Part3text15: 'a combination of design defects and bugs',
    Part3text16: 'the three guys always stick together ',
    Part3text17: 'mean, facial paralysis',
    Part3text18: 'immortal ,skilled in brainwashing',
    Part3text19: 'used to making a promise but never achieve it .',
    Part3text20: 'Opened quantity',
    Part3text21: 'To be determined',
    Part3text22: 'This Round of Sales',
    Part3text23: 'Approving',
    Part3text24: 'Buying',
    Part3text25: 'Authorization succeeded',
    Part3text26: 'Successful purchase',
    Part3text27: 'Authorization failed',
    Part3text28: 'Please authorize first',
    Part3text29: 'Canceled',
    Part3text30: 'Failed purchase',
    Part3text31: 'Insufficient authorized balance',
    Part3text32: 'Quantity issued',
    Part3text33: 'To view more',
    Part3text34: 'Countdown to first round of sales',
    Part3text35: ' per round.',
    Part3text36: 'No more',
    Part1text37: 'All NFT cards will be minted in 39 rounds,  with each round <span style="color:#F8C10A">{roundNumAll}</span> pieces, And the first three rounds will be open for free mint. ',
  },
  wall: {
    Part1Title: 'Holy Wall Worship',
    Part1text1:
      `Been rugged? Pray that someone will take over your shit. Been dumped? Pray that they will be dumped too. Want to get Rich? Hang your cards here hoping prayers will come and worship, so you can mine $SHT!See through prayers' tragedy, gather their beliefs! `,
    Part1text2:
      'Upload your Revelation Cards on the HolyWall and invite your friends to worship them, you will get Holy sh** token as a reward.',
    Part1text4: 'My cards on HolyWall',
    Part1text5: 'Pcs',
    Part1text6: 'Choose a card',
    Part1text7: 'My Karma',
    Part1text8: 'Whole network Karma',
    Part1text9: 'Cult reward',
    Part1text10: 'claim',
    Part1text11: 'Received',
    Part1text12: 'How to calculate worship reward？',
    Part1text13:
      '1. From the 1st round to the 39th round, every 2500 SHT will be released in each round. The token will be divided into 730 parts for a daily reward pool lasting 730days.',
    Part1text14:
      '2. When finished with all the 39rounds of sale, the left token will be distributed to the total reward pool. which will also be divided into 730 parts for the daily reward pool of lasting 730 days.',
    Part1text15:
      '3. The daily bonus pool comprises 2,500 coins released each round; breakdown to daily volume totals constitutes.',
    Part1text16:
      'SHT is a cryptocurrency issued based on ERC20 and is the circulating token of value for the Holy sh** Universe,',
    Part1text17:
      'mainly generated by placing Revelation tokens on the Sacred Wall for public display;',
    Part1text18:
      'SHT consumed to activate the Revelation Wall function will be directly destroyed.',
    Part1text19:
      '25% of the revenue of each Holy sh** Box will be used to buy back and destroy SHT,',
    Part1text20:
      ' and SHT exchanged for Holy sh** Number will be directly destroyed.',
    Part1text21:
      'SHT required for synthesis will also be destroyed directly',
    Part1text22: 'Quantity of current reward pool',
    Part1text23: 'Quantity of repurchased and destroyed ',
    Part2Title: '',
    Part2text1: ' images',
    Part2text2: ' Revelation Cards',
    Part2text3:
      'The street which is behind a house that made from bricks is our HolyWall.',
    Part2text4:
      'Upload your Revelation Cards on the HolyWall and invite your friends to worship them, you will get Holy sh** token as a reward.',
    Part3text1: '',
    Part3text2: ' cards are on display.',
    Part3text3: ' Private collection',
    Part3text4: ' Holy sh** Boxes are not open.',
    Part4Btn: 'Worship',
    Part4text1: 'Worship rules',
    Part4text2:
      '1.Each wallet address only has one chance to worship any one card.',
    Part4text3:
      '2.The wallet address that worshipped  a card will receive 200 Karma.',
    Part4text4: '3.If Revelation Cards of this address is not on HolyWall',
    Part4text5: 'daily worship reward of each card',
    Part4text6:
      '1.Daily worship reward of each card =(Karma of current card+ Karma of worship bonus)/ Karma of current cards that on HolyWall * Karma of daily worship reward pool',
    Part4text7: '2.Bonus karma of worship',
    Part4text8: 'worshipped',
    Part4text9: 'The amount available is 0',
    Part4text10: 'Receiving',
    Part4text11: 'Received successfully',
    Part4text12: 'Failed to claim',
    Part4text13: 'Canceled',
    Part4text14: 'You have worshipped',
    Part4text15: 'Worship success',
    Part4text16: 'Worship failure',
    Part4text17: 'Cancel worship',
    Part4text18: 'No one has worshiped it yet',
    Part4text19:
      'Place your revelation sign on the holy wall for public display',
    Part4text20: 'Let friends worship, you can receive Holy sh** Token reward',
    Part4text21: 'In worship',
  },
  bed: {
    Part1Title: '',
    Part1text1: 'Revelation Cards can be upgraded by synthesis in HolyBed!',
    Part1text2: "Yes! It's true! It's HolyBed",
    Part1text3: 'Synthesis rule is simple.',
    Part1text4: 'Never try to synthesize 3 rainbow cards',
    Part1text5:
      'Only Revelation Cards that are not on the wall can be used for synthesis.',
    Part1text6:
      'Each synthesis needs to consume some SHT，all these tokens will be destroyed directly.',
    Part1text7: 'synthesis fee',
    Part1text8: 'Confirm to synthesize？',
    Part1text9: 'use 3 cards',
    Part1text10: 'synthesis',
    Part1text11: "It's not sure what will happen.",
    Part1text12: 'Congrats to get one card!',
    Part1text13:
      'You can either upload your Revelation Cards on HolyWall to get reward or synthesize the cards into a higher quality card.',
    Part1text14: 'Synthesis Rule',
    Part1text15: 'There are no cards to synthesize at this time',
    Part1text16: 'Synthesis successful',
    Part1text17: 'Congratulations on getting a new Revelation Card',
    Part1text18: 'You have less than three cards',
    Part1text19: 'Authorization completed',
    Part1text20: 'You have authorized，now you are available for purchase',
    Part1text21: 'Authorization in progress',
    Part1text22: 'Synthesis in progress',
    Part1text23: 'You do not have authorization yet, you need to authorize to buy',

  },
  // 商店
  shop: {
    Part1text1:`where Rug U and Fur Q don't exist no more, only real stuff with a touch of heavenly humour.`,

    Part1text2: ' ',
    Part1text3: '',
    
    Part1text4:'Disclaimer: Our Holy Shxx  Merchandise is meant for entertainment purposes only. No actual deities were involved in the making of these products, and any resemblance to divine beings is purely coincidental.',
    Part1text5: 'Stay tuned with us!',
    Part2Title: 'Welcome to the Holy Shop',
    Part2text1: 'products',
    Part2text2: ' New items released',
    Part2text3: ' Type',
    Part2text4: ' Color',
    Part2text5: 'NO.',
    Part2text6: 'Price',
  },
  // 个人中心
  mine: {
    Part1Title: 'My Holy sh** Box',
    Part1text1: 'Open',
    Part1text2: 'Congratulations on getting one',
    Part1text3:
      'You can play cards in the Holy Wall to get rewards, or you can upgrade to high-quality cards',
    Part1text4: 'My Revelation Card',
    Part1text5: 'Quality',
    Part1text6: 'On the Wall',
    Part1text7: 'Desc',
    Part1text8: 'Asc',
    Part1text9: 'Revelation Card',
    Part1text10: 'Holy sh** Box',
    Part1text11: 'My Holy sh** number',
    Part1text12: '{msg} Holy sh** Numbers  {msg2} have bound.',
    Part1text13: 'Holy sh** Number is your unique ID in Holy sh**vers',
    Part1text14:
      'You must have a Holy sh** Number before you can activate your account and purchase NFT Revelation Cards',
    Part1text15:
      'Every collector can get 1 Holy sh** Number for free and give it to your friends',
    Part1text16: 'You haven’t got a Revelation Card yet, go to buy a Holy sh** Box!',
    Part1text17: `You haven't got a`,
    Part1text18: 'Bind',
    Part1text19: 'your',
    Part1text20: 'Take the card in the',
    Part1text21: 'Card successfully',
    Part1text22: 'Failed to fetch card',
    Part1text23: 'Canceled',
    Part1text24: 'Placing',
    Part1text25: 'Placed successfully',
    Part1text26: 'Redemption failed',
    Part1text27: 'Get ',
    Part1text28: 'Redeeming',
    Part1text29: 'Successful redemption',
    Part1text30: 'Redemption failed',
    Part1text31: 'Opening',
    Part1text32: 'Open failed',
    Part1text33: 'Open successfully',
    Part1text34: 'The Holy sh** Number is your unique ID at the Holy sh**vers',
    Part1text35:
      'You must have a Holy sh** Number to activate your account and purchase Revelation Cards',
    Part1text36:
      'Each collector can get a Holy sh** Number for free and give it to their friends',
    Part1text37: 'for',
    Part1text38: 'Card Details',
    Part1text39: 'For more',
    Part1text40: 'You can use',
    Part1text41: 'Exchange for more',
    Part1text42: 'Need to pay',
    Part1text43: 'SHT used for exchange will be destroyed directly',
    Part1text44: 'authorization',
    Part1text45: 'buy',
    Part1text46: 'Please stay tuned',
    Part1text47: 'Shut down',
    Part1text48: 'Please enter a 5-digit number',
    Part1text49: 'Congratulations to you，{msg} Account binding succeeded',
    Part1text50: 'You have authorized it and you can buy it directly',
    myTeam:'My team',
  },
  //活动
  active: {
    Part1text1: 'drop',
    Part1text2: 'Holy sh** REVELATION is about to go live',
    Part1text3: 'We will launch Holy sh** Number airdrops',
    Part1text4: 'The Holy sh** Number will be your pass in Holy sh** REVELATION',
    Part1text5:
      'To purchase Holy sh** Box, you need to send your wallet address first',
    Part1text6: 'Bind to Holy sh** Number',
    Part1text7: 'Activity Start Time',
    Part1text8: 'September 21, 2021, 22:00 EST',
    Part1text9: 'Airdrop quantity:',
    Part1text10: '927 Holy sh** Number',
    Part1text11: 'Activity Rules:',
    Part1text12: 'Step 1: Follow twitter accounts@Holy sh**21976012',
    Part1text13: 'Step 2: Retweet the activity and @your three friends',
    Part1text14: 'Step 3: Like and comment on the campaign tweets',
    Part1text15:
      'Step 4: Send the retweet link to the official telegraph group',
    Part1text16: 'And @ the administrator in the telegraph cluster',
    Part1text17: 'Complete the above steps to obtain a Holy sh** Number',
  },
}