import mint from './mint/zn'
export default {
  mint,
  unit: {
    unit1: '枚',
    unit2: '陳列',
    unit3: '下',
    unit4: '币',
    unit5: '个',
    unit6: ' Holy sh** Box',
    unit7: '請先在DASHBOARD中綁定Holy sh** Number',
    unit8: '暫未開放',
    unit9: 'Holy sh**檢測到您的瀏覽器未安裝TokenPocket',
    unit10:
      'TokenPocket被許多區塊鏈應用程序廣泛使用，以確保您的信息安全，请先安装TokenPocket',
    unit11: '安裝TokenPocket',
    unit12: '你安裝了TokenPocket嗎？',
    unit13: '請先綁定',
    unit14: '你安裝Metamask了嗎？',
    unit15: 'Holy sh** 檢測到您的瀏覽器未安裝 Metamask。',
    unit16:
      'Metamask 被眾多區塊鏈應用程序廣泛使用，以確保您的信息安全，請先安裝 Metamask。',
    unit17: '安裝',
    unit18: '請先綁定',
    unit19: '账户余额不足',
    unit20: '本轮已售馨',
    unit21: '复制成功',
    unit22: '網絡錯誤',
    unit23: '请将網絡更改为Arbitrum',
    unit24: 'Arbitrum One余额不足',
  },
  MetaMaskTips: '請確保您的瀏覽器已經安裝了MetaMask插件',
  button: {
    button1: '合成',
    button2: '去放置',
    button3: '收集中',
    button4: '授權',
    button5: '仍然合成',
    button6: '掛上聖牆',
    button7: '打開',
    button8: '詳情',
    button9: '掛上聖牆',
    button10: '去個人中心查看',
    button11: '購買Holy sh** Box',
    button12: '再試一次',
    button13: '再買一個',
  },
  // 头部菜单
  menu: {
    nav01: '購買盲盒',
    nav02: '聖墻膜拜',
    nav03: '聖床合成',
    nav04: 'Holy sh**商店',
    nav05: '連接錢包',
    nav06: '首頁',
    nav07: '個人中心',
    nav08: '隱私政策',
    nav09: 'DASHBOARD',
    home:'Home',
    makelove:'Make love not war',
    mintMeh:'Mint a meh',
  },
  index: {
    firstPartTitle: 'NFT 啟示牌',
    firstPartTxt1: 'Holy sh** REVELATION CARD 簡稱HRC，也叫啟示牌',
    firstPartTxt2: '是Holy sh**設計師品牌發布的加密潮流文化收藏品， ',
    firstPartTxt3:
      '首次降臨共發布<span style="color:#F8C10A">432</span>個啟示圖案，每個圖案將會發行<span style="color:#F8C10A">40</span>個NFT加密卡牌',
    firstPartTxt4: '每個啟示都圖文並茂，並隱藏著神秘的哲學意義',
    firstPartTxt5: '~ White×27 ~ Pink×9 ~ Gold×3 ~ Rainbow×1 ~',
    firstPartBtn: '購買盲盒',
    Part2Title: 'Karma與質地',
    Part2text1:
      '每張啟示牌上都會有Karma值，Karma代表着一個啟示的力量值。越高等級角色Karma基礎值越高。',
    Part2text2:
      '例如God的基礎Karma就比Fish要高，同時啟示牌的質地顏色也會帶來更高的Karma值：',
    Part2text3: '基础值',
    Part3Title: '持有一張啟示牌的三大理由',
    Part3text1: '獲得膜拜激勵',
    Part3text2: '獲得Holy sh**周邊授權收入',
    Part3text3: '獲得Holy sh**的周邊空投',
    Part3text4: 'Holy sh**商店特權',
    Part3text5: 'Holy sh**verse元宇宙特權',
    Part3text6:
      '您的每一張啟示牌都是有豐富內涵的，據說他可以給所有看到的人以非同尋常的啟示與力量。所以，您可以將啟示牌放在聖墻上，並分享給更多好友，讓他們前來膜拜，就可以獲得Holy sh**的Token激勵。',
    Part3text7:
      '如果售出商品包含了您擁有的啟示牌圖案，您和其他擁有者將一同獲得授權獎勵，每件實體週邊的售出，都會有15%的銷售利潤分給這張啟示牌的40個持有者。',
    Part3text8:
      '只要擁有一張啟示牌，就有機會獲得Holy sh**周邊空投，每當Holy sh** Store有新品發布，都會首先向聖墻上的啟示牌擁有者進行空投，你可以想象到的空投物品包括但不限於新的NFT收藏品、潮牌服飾、配飾、箱包、生活潮品等等。',
    Part3text9:
      'Holy sh**線上商店只銷售Holy sh** Revelation 品牌周邊，涵蓋潮流服飾、配飾、生活用品及各種好玩潮物。所有品牌設計都以啟示為主題，而只要你有一張啟示牌，就可以享受VIP待遇：',
    Part3text10: '特權一、全球免郵費；',
    Part3text11: '特權二、全場打九折；',
    Part3text12: '特權三、購買VIP專屬商品。',
    Part3text13:
      'Holy sh**元宇宙是全球第一個時尚潮流社交平臺，涵蓋社交、視頻、遊戲、電商、潮流等多個功能組別，這是所有Holy sh**s們線上的精神家園。這裏有好朋友，好吃的，好玩的，好看的。這裏一切皆有可能。只有啟示牌擁有者可以進入社區，並且只有啟示牌擁有者可以邀請用戶進入社區。',
    Part3text14: '特權二、全場打九折；',
    Part3text15: '特權二、全場打九折；',
    Part3text16: '特權二、全場打九折；',
    Part3text17: '特權二、全場打九折；',
    Part4Title: 'Holy sh** 號',
    Part4text1:
      'Holy sh** Number是你在Holy sh** World唯一的身份识别ID,您必须先有一个Holy sh** Number，才能激活账号并购买NFT启示牌,Holy sh** Number是你在Holy sh** World唯一的身份识别ID,您必须先有一个Holy sh** Number，才能激活账号并购买NFT启示牌。每购买一个肓盒还将再额外获得1个Number。您还可以支付Holy sh**置换Number。',
    Part4Btn1: '獲得一個Holy sh** Number',
    Part5Title: '路線圖',
    Part5text1:
      '完成NFT启示卡牌设计',
    Part5text2: '盲盒系统开发，上线发售',
    Part5text3_1:
      '圣墙膜拜激励系统的开发，激励开始',
    Part5text3:
      '完成圣床合成系统的开发，并开启启示牌合成；',
    Part5text4:
      'Holy sh**头像空投及发售',
    Part5text5:
      '首批Holy sh** REVELATION T的发售，并向启示牌持有者空投',
    Part5text6: 'Holy sh** world APP正式上线，Holy sh**元宇宙创世',
    Part6Title: 'Holy sh** 故事',
    Part6text1: '',
    Part6text2:
      'Holy sh**是幾個喜歡加密行業的潮流藝術家創辦的加密潮流收藏品品牌。Holy sh**的靈感來自於互聯網上非常流行的格言金句，每次我們看到這些充滿智慧的話語，就感覺生活中那些不順利就得到了療愈，亦或受到了一些啟發，有時候也會因此獲得一些力量。我們希望能把這些簡單的哲學通過更潮流，更街頭，更接地氣的表現形式，讓收藏者們感受到，哪怕只是靈光一閃的頓悟，也是我們的品牌價值的體現。',
    Part6text3:
      'Holy sh**代表著Decentralized（去中心化）、Indepandent（獨立的）、Artistic（藝術的）、Omni（全能的）。我們將加密技術與潮流藝術相結合，並在虛擬和現實之間搭建一個信任的橋梁，我們的產品不但要好看，好玩，還要好穿好用。Holy sh**的理想是要用加密技術，結合潮流藝術，營造一種前衛時尚的生活方式，讓每個玩家和用戶都能得到身體和靈魂的小小升華，這就是我們畢生要做的事…',
    Part6text4:
      '',
    Part7Title: '獲得Holy sh**的最新動態',
    Part7text1: '填寫郵箱，訂閱Holy sh**最新的空投及售賣消息！',
    Part7text2: '加入Holy sh**社區，跟全球收藏家溝通交流',
    Part7text3:
      '將啟示牌放在聖墻上，並分享給更多好友，讓他們來膜拜，就可以獲得Holy sh**的Token激勵',
    Part7text4: '加入Holy sh**社區，跟全球收藏家溝通交流',
    Part7text6: '加入Holy sh**社區，跟全球收藏家溝通交流',
    Part7text7: '加入Holy sh**社區，跟全球收藏家溝通交流',
    Part7text8: '加入Holy sh**社區，跟全球收藏家溝通交流',
    Part7text9: '加入Holy sh**社區，跟全球收藏家溝通交流',
    Part7text10: '訂閱',
    mPart1text1:
      'Holy sh** REVELATION CARD 簡稱HRC，也叫啟示牌。是Holy sh**設計師品牌發布的加密潮流文化收藏品。首次發布共有<span style="color:#F8C10A">432</span>個啟示圖案。每個圖案將會發行<span style="color:#F8C10A">40</span>個NFT加密卡牌。每個啟示都圖文並茂，並隱藏著神秘的哲學意義。',
    // mPart1text2: '是Holy sh**設計師品牌發布的加密潮流文化收藏品，',
    // mPart1text3: '總共鑄造17280張啟示牌，首次共發布432個啟示圖案，',
    // mPart1text4: '每個啟示圖案將會通過加密技術',
    // mPart1text5: '鑄造40張ERC721 NFT啟示牌，分別是：',
    mPart1text6: '啟示牌具有Karma值，Karma代表着一個啟示的力量值',
    mPart1text7:
      '購買啟示牌，即擁有該圖案的部分版權。可獲得圖案授權獎勵，該圖案每件周邊售出都會有15%的銷售利潤分給該啟示牌的40位持有者',
    mPart1text8:
      '啟示牌掛在聖墻上就有機會獲得周邊空投，空投物品包括NFT收藏品、服飾、配飾、箱包等',
    mPart1text9:
      '只有啟示牌擁有者可以進入社區，並且只有啟示牌擁有者可以邀請用戶進入社區',
    mPart1text10:
      'Holy sh**號是你在Holy sh**verse元宇宙唯一身份識別ID，必須拥有Holy sh**號才能激活賬號並購買啟示牌，每個玩家都可以免費額外獲得1個Holy sh**號，送給您的好友。關注Holy sh** Twitter和Discord，第一時間獲取Holy sh**號空投。',
    mPart1text11: '10月10日，全球啟示日，Holy sh** Box 正式開始發售',
    mPart1text12: '8月18日，聖墻開墻，膜拜獎勵開始釋放',
    mPart1text13: '8月28日，聖床開床，啟示牌合成升級功能開啟',
    mPart1text14: '9月1日，Holy sh**潮牌聖店開店，RevelationT  首發',
    mPart1text15:
      '期間Holy sh**官方會有很多驚喜陸續推出目前都處於保密狀態，請密切關註Holy sh**.one和官方Twitter',
    mPart1text16: '12月21日，Holy sh** App正式上線',
    Part1text17: '“Holy sh**風”頭像空投',
    Part1text18: '提交失败。請輸入正確的電子郵箱地址',
    Part1text19: '請輸入正確的郵箱地址',
    Part1text20: '訂閱成功',
    Part1text21: '在Holy sh** World社交平臺上線之前，我們將進行一輪頭像NFT資產發行',
    Part1text22:
      '“Holy sh**風”搞怪頭像是您在社交平臺上的個性體現，其超強識別度和也將讓您在世界中獨樹一幟',
    Part1text23: 'Holy sh**頭像總共發行',
    Part1text24: '個，其中將有',
    Part1text25: '會隨機空投給部分卡牌持有者',
    Part1text26: '其余将以一定价格进行认购',
    Part1text27: '/個 的價格進行認購',
    Part1text28:
      '如果您持有Holy sh** 啟示卡牌，除了可能拿到免費空投，還將進入優先認購白名單',
    Part1text29: 'Holy sh** Number空投活動將於2021-9-21 22:00 EST開啟！',
    Part1text30: '你把钱包接上了吗？',
    Part1text31: '需要连接钱包以进行下一步操作',
    Part1text32: 'WorshipReward 膜拜激勵',
    Part1text33: '獲得永续空投',
    Part1text34: 'Holy sh**社交遊戲平臺特權',
    Part1text35: 'Holy sh** 世界',
    Part1text36: '是一個加密娛樂社交平臺',
    Part1text37: 'Holy sh** World將引入很多熱門NFT加密資產遊戲',
    Part1text38: '並通過多種個性化定製功能讓各個遊戲的玩家群體能方便的交流溝通',
    Part1text39: '並可以通過Holy sh** App功能直接打開並體驗遊戲',
    Part1text40: '各個遊戲的遊戲資產也將可以在平臺上自由交易及租賃',
    Part1text41: '同時任何玩家和項目方都可以基於Holy sh**的智能NFT功能',
    Part1text42: '隨時隨地發行自己的NFT資產',
    Part1text43: 'Holy sh**的啟示牌是Holy sh** World的治理NFT',
    Part1text44:
      '所有持有啟示牌NFT的收藏家都將擁有世界的投票權、監督權等基本權益',
    Part1text45: 'Rainbow級別的啟示牌擁有平臺分紅權和專屬提案權',
    Part1text46: '相關啟示牌權益及分紅細則將在Holy sh** World上線後推出',
    Part1text47: '敬請期待',
    Part1text48: '你把Holy sh** Number绑定好了嗎？',
    Part1text49: '进行下一步操作需要将你的钱包地址与Holy sh** 号进行绑定。我们会不定期进行空投。你也可以请绑定过Holy sh** 号的好友赠送你一个',
    Part1text50: '当你第一次绑定时，我们会额外赠送你一个Holy sh** Number',
    Part1text51: '绑定',
    Part1text52: '输入Holy sh** Number',
  },
  buyBox: {
    Part1Title: '購買Holy sh** Box盲盒',
    Part1text1: '第 {msg} 輪',
    Part1text2: '第 {msgO}/{msgT} 輪發售',
    Part1text3: '輪發售',
    Part1text4: '本輪價格',
    Part1text5: '本輪數量',
    Part1text6: '開始時間',
    Part1text7: '結束時間',
    Part1text8: '等待發售開始',
    Part1text9: '購買',
    Part1text10: '本轮已售馨',
    Part1text11: '恭喜您獲得一個Holy sh** Box！',
    Part1text12: '您可以在DASHBOARD中打開',
    Part1text13: '看看有什麼驚喜！',
    Part1text14: '去DASHBOARD查看',
    Part2Title: 'Holy sh** Box發行方案',
    Part2text1: '所有NFT卡牌將分',
    Part2text2: '輪全部發售完畢',
    Part2text3: '每輪將只發售',
    Part2text4: '個Holy sh** Box，首輪價格為',
    Part2text5: '此後每輪價格將增加',
    Part2text6:
      '我們將只預留432個盒子用於空投及活動獎勵。每輪Holy sh** Box發售時間請及時關註Holy sh**官方Twitter及郵件',
    Part3Title: 'Holy sh** 角色',
    Part3text1: '首批降临的有9個角色，他們分別是：',
    Part3text2: '自卑又樂觀的魚胖',
    Part3text3: '失憶，無腦，跟自己的世界玩',
    Part3text4: '性愛，模仿人類',
    Part3text5: '腦回路簡單跳躍',
    Part3text6: '懶惰而膽小，懦弱而狡猾',
    Part3text7: '偶爾會長出手',
    Part3text8: '固執，敏感，邋遢',
    Part3text9: 'loser心態',
    Part3text10: '灰人與貓的結合體',
    Part3text11: '高冷，警覺，隨時變臉',
    Part3text12: '屠夫，一直處於憤怒狀態',
    Part3text13: '無法正常生活',
    Part3text14: '工具人，007工作',
    Part3text15: '設計缺陷與Bug的共同體',
    Part3text16: '永遠三個粘在一起',
    Part3text17: '刻薄，面癱',
    Part3text18: '永生，善於洗腦',
    Part3text19: '善於答應別人，但經常做不到',
    Part3text20: '已開出數量',
    Part3text21: '待定',
    Part3text22: '本輪售出實況',
    Part3text23: '授權中',
    Part3text24: '購買中',
    Part3text25: '授權成功',
    Part3text26: '購買成功',
    Part3text27: '授權失敗',
    Part3text28: '請先授權',
    Part3text29: '取消',
    Part3text30: '購買失敗',
    Part3text31: '授權余額不足',
    Part3text32: '已開出數量',
    Part3text33: '查看更多',
    Part3text34: '距離Holy sh** Box首輪開售：',
    Part3text35: '',
    Part3text36: '沒有更多了',
    Part1text37: '所有NFT卡牌将分为<span style="color:#F8C10A">{roundNum}</span>轮，<br/>每轮发售<span style="color:#F8C10A">{roundNumAll}</span>个Holy sh** Box，首轮价格待定，<br/>此后每轮将在前一轮的基础上涨价',
  },
  wall: {
    Part1Title: '聖墻膜拜',
    Part1text1: '這是一面磚頭砌成的牆，面向房子後面的街區，這就是我們的聖牆',
    Part1text2:
      '將您的啟示牌放置在聖牆上公開陳列，讓好友們來膜拜，即可收到Holy sh**的Token獎勵',
    Part1text4: '我在牆上的卡牌',
    Part1text5: '張',
    Part1text6: '选择卡牌',
    Part1text7: '我的Karma',
    Part1text8: '全網Karma',
    Part1text9: '我的獎勵',
    Part1text10: '領取',
    Part1text11: '已領取',
    Part1text12: '膜拜激勵是怎麼計算的？',
    Part1text13:
      '1.每轮售罄时會釋放2500枚Holy sh** Coin，並分成730份，每天一份進入後續730天的每日獎勵池',
    Part1text14:
      '2.當39輪全部售完，剩余所有Token會一並投入總獎勵池，並且也分成730份，每天一份，進入後續730天每日獎勵池',
    Part1text15:
      '3.每日獎勵池就是由每輪釋放的2500枚幣，拆分到每日的數量總合構成',
    Part1text16: 'SHT是基於ERC20發行的加密貨幣',
    Part1text17: '是Holy sh**元宇宙的價值流通代幣,主要通过将启示牌放置在圣墙上公开陈列产出',
    Part1text18: '激活启示牌上墙功能消耗的SHT将直接销毁',
    Part1text19: '每個Holy sh** Box收入的25%將用於回購銷毀SHT',
    Part1text20: '兌換Holy sh** Number的SHT將直接銷毀',
    Part1text21: '合成所需的SHT也將直接銷毀',
    Part1text22: '當前激勵池數量',
    Part1text23: '已释放激励数量 ',
    Part2Title: '',
    Part2text1: '個圖案',
    Part2text2: '張啟示牌',
    Part2text3: '這是一面磚頭砌成的牆，面向房子後面的街區，這就是我們的聖牆',
    Part2text4:
      '將您的啟示牌放置在聖牆上公開陳列，讓好友們來膜拜，即可收到Holy sh**的Token獎勵',
    Part3text1: '',
    Part3text2: '張陳列中',
    Part3text3: '張私人收藏',
    Part3text4: '個尚未開出',
    Part4Btn: '膜拜一下',
    Part4text1: '膜拜規則',
    Part4text2: '1.每個地址只能膜拜一次，不管是哪個卡',
    Part4text3: '2.膜拜後，該地址可獲得200 Karma ',
    Part4text4: '3.此地址如果沒有卡牌陳列在聖墻上，則200 Karma無效',
    Part4text5: '每卡每日獲得膜拜獎勵值',
    Part4text6:
      '1.每卡每日獲得膜拜獎勵值 = ( 此卡面值Karma + 此卡膜拜加成Karma ) ÷ 當前墻上總Karma值 × 當日膜拜激勵池',
    Part4text7:
      '2.每次有效膜拜給卡片加成Karma = （ 面值Karma - 此卡已加成Karma值 ）× 1%',
    Part4text8: '已被膜拜',
    Part4text9: '可領取數量為0',
    Part4text10: '領取中',
    Part4text11: '領取成功',
    Part4text12: '領取失敗',
    Part4text13: '取消',
    Part4text14: '你已經膜拜過',
    Part4text15: '膜拜成功',
    Part4text16: '膜拜失敗',
    Part4text17: '取消膜拜',
    Part4text18: '還沒有人膜拜',
    Part4text19: '將您的啟示牌放置在聖牆上公開陳列',
    Part4text20: '讓好友們來膜拜，即可收到Holy sh**的Token獎勵',
    Part4text21: '膜拜中',

  },
  bed: {
    Part1Title: '',
    Part1text1: '在聖床上，啟示牌可以進行合成升級',
    Part1text2: '是的，你沒看錯，是聖床',
    Part1text3: '合成規則非常簡單：',
    Part1text4: '永遠都不要嘗試用三個Rainbow去合成',
    Part1text5: '只有未在墻上的啟示牌才可以用來合成',
    Part1text6: '每次合成需要消耗一些SHT，這些Token將直接銷毀',
    Part1text7: '合成費用',
    Part1text8: '確定要合成嗎？',
    Part1text9: '使用三張',
    Part1text10: '品質的卡牌進行合成',
    Part1text11: '不確定會發生什麽情況',
    Part1text12: '恭喜你獲得一張',
    Part1text13: '你可以將卡牌陳列於聖墻獲取獎勵，或者合成更高品質的卡牌',
    Part1text14: '合成規則',
    Part1text15: '暂无卡牌可以合成',
    Part1text16: '合成成功',
    Part1text17: '恭喜你獲得一張新的啟示牌',
    Part1text18: '您的卡片還不足三張',
    Part1text19: '授權完成',
    Part1text20: '您已授權，可以去合成了',
    Part1text21: '授權中',
    Part1text22: '合成中',
    Part1text23: '您還沒有授權，請授權後合成',
  },
  // 商店
  shop: {
    Part1text1:`Dah da, it's the ultimate Holy Shop: where Rug U and Fur Q don't exist no more, only real stuff with a touch of heavenly humour. `,

    Part1text2: ' ',
    Part1text3: '',
    
    Part1text4:'Disclaimer: Our Holy Shxx  Merchandise is meant for entertainment purposes only. No actual deities were involved in the making of these products, and any resemblance to divine beings is purely coincidental.',
    Part1text5: '敬請期待',
    Part2Title: 'Holy Shop',
    Part2text1: '款商品',
    Part2text2: '款新品上架',
    Part2text3: '类别',
    Part2text4: '颜色',
    Part2text5: '号码',
    Part2text6: '价格',
  },
  // 个人中心
  mine: {
    Part1Title: '我的盲盒',
    Part1text1: '已开',
    Part1text2: '恭喜你獲得一張',
    Part1text3: '你可以將卡牌陳列於聖墻獲取獎勵，或者合成更高品質的卡牌',
    Part1text4: '我的啟示牌',
    Part1text5: '品質',
    Part1text6: '是否陳列',
    Part1text7: '由近及遠',
    Part1text8: '由遠及近',
    Part1text9: '啟示牌',
    Part1text10: 'Holy sh** Box',
    Part1text11: '我的Holy sh** Number',
    Part1text12: '{msg} 個Holy sh** Number {msg2} 已綁定',
    Part1text13: 'Holy sh** Number是你在Holy sh**vers唯一的身份識別ID',
    Part1text14: '您必須先有一個Holy sh** Number，才能激活賬號並購買NFT啟示牌',
    Part1text15: '每個玩家都可以免費獲得1個Holy sh** Number，送給您的好友',
    Part1text16: '您还没有啟示牌，快去購買Holy sh** Box吧',
    Part1text17: '您还没有',
    Part1text18: '綁定',
    Part1text19: '我的',
    Part1text20: '取卡中',
    Part1text21: '取卡成功',
    Part1text22: '取卡失敗',
    Part1text23: '取消',
    Part1text24: '放置中',
    Part1text25: '放置成功',
    Part1text26: '放置失敗',
    Part1text27: '再來一個',
    Part1text28: '兑换中',
    Part1text29: '兑换成功',
    Part1text30: '兑换失敗',
    Part1text31: '打開中',
    Part1text32: '打開失敗',
    Part1text33: '打開成功',
    Part1text34: 'Holy sh** Number是你在Holy sh**verse唯一的身份識別ID',
    Part1text35: '必須拥有Holy sh** Number才能激活賬號並購買啟示牌',
    Part1text36: '每個玩家都可以免费獲得1個Holy sh** Number，赠送好友',
    Part1text37: '兌換',
    Part1text38: '啟示牌詳情',
    Part1text39: '獲取更多',
    Part1text40: '你可以使用',
    Part1text41: '兌換更多的',
    Part1text42: '需要支付',
    Part1text43: '兌換用的SHT將直接銷毀',
    Part1text44: '授權',
    Part1text45: '購買',
    Part1text46: '敬請期待',
    Part1text47: '關閉',
    Part1text48: '請輸入一個5位數的數字',
    Part1text49: '恭喜您，{msg} 賬號綁定成功',
    Part1text50: '您已經授權了，可以直接購買',
    myTeam:'My team',
  },
  //活动
  active: {
    Part1text1: '空投',
    Part1text2: '在Holy sh** REVELATION即將上線之際',
    Part1text3: '我們將啟動Holy sh** Number空投活動',
    Part1text4: 'Holy sh** Number将是您在Holy sh** REVELATION中的通行證',
    Part1text5: '購買Holy sh** Box需先將您的錢包地址',
    Part1text6: '與Holy sh** Number進行綁定',
    Part1text7: '活動開始時間',
    Part1text8: '2021年9月21日上午10：00',
    Part1text9: '空投數量：',
    Part1text10: '927個Holy sh** Number',
    Part1text11: '活動規則：',
    Part1text12: '步驟1： 關註推特賬號@Holy sh**21976012',
    Part1text13: '步驟2： 轉發活動推文內容並@你的三個好友',
    Part1text14: '步驟3： 點贊並評論活動推文',
    Part1text15: '步驟4： 把轉推的鏈接發送給官方電報群',
    Part1text16: '並@電報群裏的管理員',
    Part1text17: '完成以上步驟即可獲得一個Holy sh** Number',
  },
}