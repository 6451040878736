const mutations = {
   Accounts(state, data){
      state.accounts = data
   },
   Install(state, data){
      state.install = data
   },
   GasPrice(state, data){
    state.gasPrice = data
   },
   ConnectWallet(state, data){
      state.connectWallet = data
   },

   notloing(state, data){
    state.notloing = data;
   },
   showInfo(state, data){
    state.showInfo = data;
   },
   // accountsdata(state, data){
   //  state.accounts = (data);
   // },
   footerbackimg(state, data){
    state.footerbackimg = data;
   },
   Bedfooterbackimg(state, data){
    state.Bedfooterbackimg = data;
   },
   headbackimg(state, data){
    state.headbackimg = data;
   },
   buyBoxblockHash(state, data){
    state.buyBoxblockHash = data;
   },
   fromAddress(state, data){
    state.fromAddress = data;
   },
   cardTokenId(state, data){
    state.cardTokenId = data;
   },
   transactionHash(state, data){
    state.transactionHash = data;
   },
   Token(state, data){
    state.Token = data;
   },
   worshipGetInfo(state, data){
    state.worshipGetInfo = data;
   },
   showLoading(state, data){
      state.showLoading = data;
   },
   boxNum(state, data){
      state.boxNum = data;
   },
   CardNum(state, data){
      state.CardNum = data;
   },
   diaoNum(state, data){
      state.diaoNum = data;
   },
   networkError(state, data){
      state.networkError = data;
   },
}
export default mutations;