import iView from 'iview';
import '@/assets/css/bootstrap.min.css'
import 'iview/dist/styles/iview.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/vueI18n/index';
import Web3 from 'web3';
import ElementUI from 'element-ui';
import { Api } from '@/api/api'
import config from "@/assets/config/index"
import 'element-ui/lib/theme-chalk/index.css';

import 'normalize.css'
import 'vant/lib/index.css';
import './utils/rem'
import { floatObj } from '@/utils/index'
import { utils } from '@/utils/utils'
import '@/assets/iconFont/iconfont.css'
import '@/assets/style/global.less';
import '@/assets/css/base.css';
import { Toast} from 'vant';
import '@/assets/css/animate.min.css'

Vue.prototype.$floatObj = floatObj
// 全局toast
Vue.prototype.$Toast = Toast
Vue.prototype.Web3 = Web3;
// 公共方法 utils
Vue.prototype.$utils = utils
Vue.config.productionTip = false;
Vue.prototype.$config = config;
//接口
Vue.prototype.$request = Api
import WOW from 'wowjs'
Vue.prototype.$WOW = WOW
Vue.use(ElementUI);
new Vue({
  router,
  store,
  i18n,
  iView,
  render: h => h(App)
}).$mount('#app')
