const state = {
  accountsTnitial:null,
  showLoading:false,
  diaonumber:null,//diao号
  notloing:false,
  showInfo:false,
  erccontract:null,
  contract:null,
  accounts:null,
  balance:null,//账户地址的余额
  footerbackimg:false,//底部背景图
  Bedfooterbackimg:false,
  headbackimg:false,//头部导航栏背景图
  language:'en-us',
  show_succeed:false,//购买盒子成功弹窗
  buyBoxblockHash:null,//购买盲盒返回blockHash值
  totemShopCont:null,//购买盒子合约
  signVal:null,
  Token:null,
  fromAddress:null,//卡牌详情地址
  cardTokenId:null,//卡牌id
  transactionHash:null,//卡牌Hash
  worshipGetInfo:[],//膜拜卡牌详情
  wethContract:null,//weth币合约
  diaoCoinContract:null,//diao币合约
  buyDiaNumberContract:null,//购买DiaNumber合约
  networkError:false,
  accountAlert:false,//钱包链接弹窗
  getDiaoNumAlert:false,//diaoNum绑定弹窗
  boxNum:0,//盲盒數量
  CardNum:0,//卡牌數量
  diaoNum:0,//diao號數量
  holyKarmaContract:null,//圣墙合约
  earnedContract:null,
  worshcardTotem:null,
  buyBadgeContract:null,
  isActive:-1,

  install: null, //是否安装钱包
  netErr: null, //网络错误
  wallet_show: false, //未安装弹框
  net_show: false, //网络错误弹框
  accounts_show: false, //链接钱包弹窗
  gasPrice: 0,
  connectWallet: true, //是否链接钱包true-未连接，false-已连接
  msg:'',//加载蒙层
  urltarget:'',

}

export default state;