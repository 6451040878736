import Web3 from 'web3'
import { Toast } from 'vant'
import store from '@/store'
import { api_game } from '@/api/api'
import config from '@/assets/config/index'

import { } from '@/abi/abi';

console.log('web3=====', Web3.version)

var ethereum
var accounts = [],
  gasPrice = 0

  //当前网络错误为true 
var netErr = localStorage.getItem('netErr') || true,
  install = localStorage.getItem('install') || true,
  accountsShow = localStorage.getItem('accountsShow') || true
var menuState = window.localStorage.getItem("menuState");

export const getWebData = async (to, from, next) => {
  if (typeof window.ethereum === 'undefined') {
    // store.state.install = false
    install = true
    localStorage.setItem('install',install)
    localStorage.removeItem("accounts");
    localStorage.removeItem("gasPrice");
    next()
    return;
  }else {
    install = false
    // store.state.install = true
    console.log(store.state.install)
  }
  localStorage.setItem('install',install)
  ethereum = window.ethereum
  console.log('web3 start')
  window.web3 = new Web3(ethereum)
  try {
    if(!store.state.accounts){
      accounts = await web3.eth.getAccounts() || null;
      console.log('获取钱包',accounts)
    }
    // gasPrice = await web3.eth.getGasPrice() || null;
    // console.log(gasPrice,'===gasPrice')
    
    // 判断网络
    // try {
    //   // var netId =await web3.eth.net.getId()
    //   web3.eth.net.getId().then(netId=>{
    //     console.log(netId,config.netId)
    //     netErr = netId != config.netId ? false : true
    //     localStorage.setItem('netErr',netErr)
    //     console.log(netId,'===netId===',config.netId,netErr)
    //   })
    // } catch (error) {
    //     console.log(error,'===netId=error')
    // }
  } catch (error) {
    console.log(error)
  }
  console.log(accounts,'===accounts')
  accounts.length <= 0 ? accounts = null: accounts
  if (accounts === null) {
    accountsShow = true
  }else{
    accountsShow = false
    console.log(accounts[0].toLowerCase())
    store.state.connectWallet = false
    store.dispatch("AccountsVal", accounts[0].toLowerCase());
    localStorage.setItem("accounts",accounts[0].toLowerCase())

    store.dispatch("GasPriceVal", gasPrice);
    localStorage.setItem("gasPrice",gasPrice)
  }
  
  localStorage.setItem('accountsShow',accountsShow)
  // localStorage.setItem('netErr',netErr)
  console.log(netErr,'====netErr',gasPrice,'---gasPrice',install,'install',store.state.accounts,'accounts')
  next()
  

}

export const getContract = async () => {
  // 合约实例化
  try {
    window.web3 = new Web3(ethereum)
    
    
    
  } catch (err) {
    install = false
    console.log(err)
  }
}


