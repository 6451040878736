import Vue from 'vue';
import store from '@/store';
import VueI18n from 'vue-i18n';
import zn from './zn';
import en from './en';

Vue.use(VueI18n);

// 定义各语言的配置
let messages = {
    "zh-cn": zn, // 中文
    "en-us": en, // 英文
};
// 自动设置语言
// let navLang = navigator.userLanguage||window.navigator.language;
//  console.log(navigator.userLanguage,window.navigator.language)
// navLang = navLang.toLowerCase()
let navLang = "en-us"
let localLang = (navLang === 'en-us' || navLang === 'zh-cn') ? navLang : false;
let lang = window.localStorage.getItem('lang') || localLang || 'zh-cn';

lang = lang.toLowerCase()

// var body = document.body;
// body.className = lang;

console.log(lang,'===lang')

// 获取持久化语言状态
// let locale = lang || 'zh-cn'
let locale = lang || 'zh-cn'

store.state.language = locale
localStorage.setItem('lang',locale)

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
    locale: store.state.language, // 设置默认地区
    messages, // 设置配置信息
});

export default i18n;