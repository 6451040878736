
 import axios from 'axios';
 import { Toast } from 'vant';
 import store from '@/store/index';
 import QS from 'qs';
 import Web3 from 'web3';
 import {Api} from '@/api/api';
 import router from '../router';
 import config from "@/assets/config/index"

 const instance = axios.create({
    baseURL: config.baseUrl,
    timeout: 60000,
  })
 instance.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    console.log('error',error)
    return Promise.reject(error);
  });

instance.interceptors.response.use(function (response) {
    if (response.status === 200) {
        return Promise.resolve(response); 
    } else {
        return Promise.reject(response);        
    }  
  }, function (err) {
    if (err && err.response) {
        switch (err.response.status) {
          case 400:
            err.message = 'Request error';
            break
          case 401:
            err.message = 'Unauthorized, please log in';
            break
          case 403:
              console.log( 'Web3.version:'+Web3.version);
              router.push('/index')
              err.message = 'Please log in first';
            break
          case 404:
            err.message = `Error request address:${err.response.config.url}`;
            break
          case 408:
            err.message = 'Request timed out';
            break
          case 500:
            err.message = 'Server internal error';
            break

          case 501:
            err.message = 'Service not implemented';
            break

          case 502:
            err.message = 'Gateway error';
            break

          case 503:
            err.message = 'service is not available';
            break

          case 504:
            err.message = 'Gateway timeout';
            break

          case 505:
            err.message = 'HTTP version is not supported';
            break

          default:
        }
    }
    console.log(err.message)
    return Promise.reject(err)
  });
 
 export function get(url,data = {}){
  return new Promise((resolve,reject) => {
    instance.get(url,data)
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}

export function post(url,data = {}){
  return new Promise((resolve,reject) => {
    instance.post(url,data)
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}

 export function Delete(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.delete(url, params)
          .then(response => {
              resolve(response.data);
          },err => {
            reject(err)
        })
  });
}
