<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  created(){
    // let htmlwidth =document.documentElement.clientWidth || document.body.clientWidth;
    //     let htmlDom = document.getElementsByTagName('html')[0];
    //     htmlDom.style.fontSize=parseInt(htmlwidth /26)  +'px';
    //     console.log(htmlwidth,htmlDom.style.fontSize);
  },
  destroyed: function(){
    this.$store.commit("notloing", false);
  },
}
</script>

<style lang="less">
// @import './assets/style/global.less';
// @import './assets/css/base.css';
@media screen and (max-width: 767px) {
  @import '@/src/assets/style/h5/publicH5.css';
}
img{
  max-width: 100%;
}
</style>
