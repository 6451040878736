import zn from '@/vueI18n/zn'
import en from '@/vueI18n/en'

var baseLang = 'en-us'//en-us or zh-cn
var filet = '',lang = window.localStorage.getItem('language') || baseLang
filet = lang == 'zh-cn' ? zn : en //语言包
baseLang = 'en-us'//en-us or zh-cn
let port = 8020,//测试
// let port = 8086,//正式
  env = process.env.NODE_ENV
// 正式8086 其余的均为测试，区别合约地址
// process.env.NODE_ENV   本地:development  打包后:production
let config = {
  baseUrl: port == 8086 ? '' : '',
  baseLang:baseLang,
  port: port, //端口
  // netId: port != 8086 ? 97 : 56,//网络Id
  netId: port != 8086 ? 11155111 : 11155111,//网络Id
  hashContract:port != 8086 ?'':'',
  // netId: 4 , //正式-网络Id
  hexNetId:'0x4',//正确网络转换成16进制的网络Id
  inviteUrl: location.href.split('#')[0], //邀请链接
  fromAddress: '0x0000000000000000000000000000000000000000',
  feeConfig:{name:'3%',decimal:0.03},
  imgSrc:'',//图片域名
  twitterUrl:'https://x.com/Furry_LegendFLC',
  tgUrl:'https://t.me/FurryLegendsOfficial',
}
export default config
