import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout/index.vue';
import { Toast } from 'vant';
import i18n from '@/vueI18n/index';
import { getWebData, getContract } from '@/web3'
import Web3 from 'web3';
import {Api} from '@/api/api';
import store from '@/store/index';

Vue.use(VueRouter)

const routes = [
  // {
  //   // 落地页
  //   path: '/',
  //   name: 'LandingPage',
  //   meta:{isOpen:true},
  //   component: () => import(/* webpackChunkName: "LandingPage" */ '@/views/landingPage/index.vue')
    
  // },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/index', // 重定向到首页
    meta:{isOpen:true},
    children: [
      {
        // 首页
        path: '/index',
        name: 'Index',
        meta:{isOpen:true,wallBg:false},
        component: () => import(/* webpackChunkName: "Index" */ '@/views/home/index.vue')
      },
      // {
      //   // 购买盲盒
      //   path: '/buyBox',
      //   name: 'buyBox',
      //   meta:{isOpen:true,wallBg:false},
      //   component: () => import(/* webpackChunkName: "buyBox" */ '@/views/buyBox/index.vue')
      // },
      // {
      //   // 购买徽章
      //   path: '/badge',
      //   name: 'badge',
      //   meta:{isOpen:true,wallBg:false},
      //   component: () => import(/* webpackChunkName: "buyBox" */ '@/views/badge/index.vue')
      // },
      // {
      //   // 个人中心
      //   path: '/mine',
      //   name: 'Mine',
      //   redirect: '/Orders', // 重定向到首页
      //   meta:{isOpen:true,wallBg:false},
      //   component: () => import(/* webpackChunkName: "mine" */ '@/views/mine/index.vue'),
      //   children: [
      //     {
      //       // 啟示牌
      //       path: '/cardList',
      //       name: 'cardList',
      //       meta:{isOpen:true,wallBg:false},
      //       component: () => import(/* webpackChunkName: "mine" */ '@/views/mine/components/CardList.vue')
      //     },
      //     {
      //       // 盲盒
      //       path: '/boxList',
      //       name: 'boxList',
      //       meta:{isOpen:true,wallBg:false},
      //       component: () => import(/* webpackChunkName: "mine" */ '@/views/mine/components/BoxList.vue')
      //     },
      //     // {
      //     //   // diao号
      //     //   path: '/diaoNum',
      //     //   name: 'diaoNum',
      //     //   component: () => import(/* webpackChunkName: "mine" */ '@/views/mine/components/diaoNum.vue')
      //     // },
      //     {
      //       // Orders
      //       path: '/Orders',
      //       name: 'Orders',
      //       meta:{isOpen:true,wallBg:false},
      //       component: () => import(/* webpackChunkName: "Orders" */ '@/views/mine/components/Orders.vue')
      //     },

      //   ],
      // },
      // {
      //   //圣墙膜拜
      //   path:'/wallSt',
      //   name:'wallSt',
      //   meta:{isOpen:true,wallBg:true},
      //   component:() => import(/* webpackChunkName: "wallSt" */ '@/views/wallSt/index.vue'),
      // },
      // {
      //   // Pepe Fish
      //   path: '/fishPepe/:roleId',
      //   name: 'fishPepe',
      //   meta:{isOpen:true,wallBg:true},
      //   component: () => import(/* webpackChunkName: "fishPepe" */ '@/views/wallSt/view/fishPepe.vue'),
      //  },
      //  {
      //   // Pepe three
      //   path: '/threeForever/:roleId/:imgId',
      //   name: 'threeForever',
      //   meta:{isOpen:true,wallBg:true},
      //   component: () => import(/* webpackChunkName: "threeForever" */ '@/views/wallSt/view/threeForever.vue'),
      //  },
      //  {
      //   // Pepe worship
      //   path: '/worship/:roleId/:imgId/:tokenId',
      //   name: 'worship',
      //   meta:{isOpen:true,wallBg:true},
      //   component: () => import(/* webpackChunkName: "worship" */ '@/views/wallSt/view/worship.vue'),
      //  },
      //  {
      //   //圣床
      //   path:'/bed',
      //   name:'bed',
      //   meta:{isOpen:false,wallBg:false},
      //   component:() => import(/* webpackChunkName: "wallSt" */ '@/views/bed/index.vue'),        
      // },
      // {
      //   //商店
      //   path:'/shop',
      //   name:'shop',
      //   meta:{isOpen:true,wallBg:false},
      //   component:() => import(/* webpackChunkName: "wallSt" */ '@/views/shop/index.vue'),    
      // },
      // {
      //   //商店活动页
      //   path:'/activity',
      //   name:'activity',
      //   meta:{isOpen:false,wallBg:false},
      //   component:() => import(/* webpackChunkName: "activity" */ '../views/shop/views/activity.vue'),    
      // },
      // {
      //   // Conditions
      //   path: '/Conditions',
      //   name: 'Conditions',
      //   meta:{isOpen:false,wallBg:false},
      //   component: () => import(/* webpackChunkName: "Conditions" */ '@/views/home/views/Conditions.vue')
      // },
      // {
      //   // policy
      //   path: '/policy',
      //   name: 'policy',
      //   meta:{isOpen:false,wallBg:false},
      //   component: () => import(/* webpackChunkName: "policy" */ '@/views/home/views/policy.vue')
      // },
      {
        path: '*',
        redirect: '/index',
        name: 'notFound',
        hidden: true
      }
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
    scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0
      }
    }
  },
  linkExactActiveClass:'show',
  routes,

})

// router.beforeEach(async(to, from, next) => {
//   if(to.meta.isOpen){
//     getWebData(to, from, next)
//   }else{
//     Toast('coming soon');
//     router.push('/index')
//     store.state.isActive = 0
//   }
// })
// router.afterEach(async()=>{
//   await getContract()
// })
// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
