export default{
  text01:'HASH CONTRACT :',
  text02:`Join our free mint event! Mint an Alien (RUG U team) or a God (FUR Q team); win missions; enjoy social events and earn exclusive rewards. Don't miss out on this epic adventure!`,
  text03:'Read full story',
  text04:'Free Mint',
  text05:'Token ID',
  text06:'No, I am not always right. But we will we will Fur Q. Join my team to the anti-rug moon.',
  text07:`Hey, you ever rugged anyone? If you not, you better go to the other team now. Don't stay around here.`,
  text08:'After God discovered the world of web3 and he started to invest in many projects that he found to be great.',
  text09:'However, God fell prey to a rumor spread by the Aliens. He invested in their cosmic blockchain project, only to discover that the aliens rug pulled him, causing him to lose his funds. Filled with anger, God decided to rally everyone and launch a mission to track down the aliens and seek repayment.',
  text10:`However, the crafty aliens got wind of God's intentions and started using received funds to create deceptive missions. Their aim was to manipulate the crowd into turning against God and attempting to overthrow him. God was always known as the brainwasher (which aren't helpful in the world of web3, people are sensitive to brainwashers on web3 hehe), and aliens haven't left too many bad reputations on earth since their existence isn't even a popular thing.`,
  text11:`As the chase unfolded, a hilarious game of cat and mouse ensued. God found himself entangled in a web of absurd challenges and tricks set by aliens, who were determined to bring him down using fraudulent means.`,
  text12:'How would this turn out... Nobody will know. And good luck to God and Aliens both...',
  text13:`This NFT collection is called God VS Alien. You will be able to mint either one of them randomly, therefore you will be assigned a role with either God or Alien. Users will be assigned into the God team or the Alien team and begin their future tasks, campaigns or social events. By joining this innovative minting event, you will get an early access to events that will potentially bring you airdrops, benefits and more!!!`,
  text14:`*Notice: This is a free mint event and you will be randomly given to one of the NFTs once you mint.`,
  text15:'Each account can only mint once.',
}