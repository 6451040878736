import { Toast } from 'vant';
import i18n from '../vueI18n/index'
import store from '@/store'

// 复制
export const copyText = (value,startext) => {
    var input = document.createElement("input"); // 直接构建input
    input.value = value; // 设置内容
    document.body.appendChild(input); // 添加临时实例
    input.select(); // 选择实例内容
    document.execCommand("Copy"); // 执行复制
    document.body.removeChild(input); // 删除临时实例
    Toast(i18n.t("unit.unit21"));
}
//格式化字符串
export const formatStr = (str) => {
    let subStrstart=str.substring(0,7);
    let subStrend=str.substring(str.length- 4);
    str=subStrstart + "..." + subStrend ;
    console.log(str);
    return str;
    
}
// 添加网络
export const networkAdd = (chainId, chainName, symbolName, rpcUrls, blockExplorerUrls) =>{
    var ethereum;
    ethereum = window.ethereum
    console.log("ethereum",ethereum)
    
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: chainId,//链 ID
          chainName: chainName,//符号
          nativeCurrency: {
            name: symbolName,
            symbol: symbolName,
            decimals: 18,
          },
          rpcUrls: [rpcUrls],//节点浏览器
          blockExplorerUrls: [blockExplorerUrls],//区块浏览器 URL
        },
      ],
    })
    .then(() => {
        store.state.networkError = false;
      console.log("网络切换成功");
    })
    .catch((e) => {});
}
// 切换网络
export const networkChange = (chainId) =>{
    var ethereum;
    ethereum = window.ethereum
    console.log("ethereum",ethereum)
    
    window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId: chainId,//链 ID
          },
      ],
    })
    .then(() => {
        store.state.networkError = false;
      console.log("网络切换成功");
    })
    .catch((e) => {});
}
//格式化tokenId
export const formatTokenid = (tokenId) => {
    // let tokenId = '10010011011'
    // let tokenId = '10020113011';
    tokenId =  String(tokenId)
    let starToken = tokenId.substring(3,4);
    let centerToken = tokenId.substring(5,7);
    let endToken = tokenId.substring(7,8);
    endToken = Number(endToken);
    endToken += 1;
    endToken =String(endToken);
    console.log(starToken+centerToken+endToken,"格式化Token")
    return starToken+centerToken+endToken;
  }

/*
时间格式化
separated：分隔符
date：日期
*/
export const formateTime =(separated,date)=>{
    var separated = separated;
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    var strHours = date.getHours();
    var strMinutes = date.getMinutes();
    var strSeconds = date.getSeconds();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    if (strHours >= 0 && strHours <= 9) {
        strHours = "0" + strHours;
    }
    if (strMinutes >= 0 && strMinutes <= 9) {
        strMinutes = "0" + strMinutes;
    }
    if (strSeconds >= 0 && strSeconds <= 9) {
        strSeconds = "0" + strSeconds;
    }
    var currentdate = year + separated + month + separated + strDate +' '+ strHours+':' + strMinutes+':' + strSeconds;
    console.log(currentdate)
    return currentdate;
}
/**
 * @description 防抖 debounce
 * @param {*} func 函数体
 * @param {*} time 延时时间
 * @param {*} once 立即执行
 * @returns fun
 */
 export const debounce = (func, time, once = true) => {
    let timer = null

    return function(){
        const context = this
        const args = arguments
        timer && clearTimeout(timer)

        if (once) {
            // 立即执行
            
            let callNow = !timer

            timer = setTimeout(() => {
                timer = null
            }, time)

            callNow && func.apply(context, arguments)
        } else {
            // 非立即执行

            timer = setTimeout(() => {
                func.apply(context, args)
            }, time)
        }
    }
}


/**
 * @description 节流
 * @param {*} func 函数体
 * @param {*} time 延迟毫秒数
 * @param {*} once 立即执行
 */
export const throttle = (func, time, once = true) => {
    if(once){
        let prev = 0
    } else {
        let timer = null
    }

    return function(){
        const context = this
        const args = arguments

        if (once) {
            // 立即执行 时间戳版
            let now = new Date.now()

            if (now - prev > time) {

                func.apply(context, args)
                prev = now
            }
        } else {
            // 非立即执行 定时器版

            if(!timer) {
                timer = setTimeout(() => {

                    timer = null
                    func.apply(context, args)
                }, time)
            }
        }
    }
}

/**
 * 
 * @param { Array } data 数据源
 * @param { String } value 排序的属性
 * @param {*} way 升序还是降序 DROP 降序
 * @returns 
 */
export const mySort = (data, value, way = 'DROP') => {
    let dataList = []
    if (way === 'DROP') {
        dataList = data.sort(function(item1, item2){
           return item1[value] - item2[value]
        })
    } else {
        dataList = data.sort(function(item1, item2){
            return item2[value] - item1[value]
        })
    }

    return dataList
}

/**
 * @description 判断是否到某个时间
 * @param {*} time 时间 2021/06/06 15:00:00
 */
export const isEndTime = (time = '2021/06/06 15:00:00') => {
    let now = new Date().getTime(),
    times = new Date(time).getTime();

    let diff = times - now
    if(diff > 0){
        return false
    }

    return true
}

/**
* floatObj 包含加减乘除四个方法，能确保浮点数运算不丢失精度
*
* 精度丢失问题（或称舍入误差，其根本原因是二进制和实现位数限制有些数无法有限表示
* 以下是十进制小数对应的二进制表示
*      0.1 >> 0.0001 1001 1001 1001…（1001无限循环）
*      0.2 >> 0.0011 0011 0011 0011…（0011无限循环）
* 计算机里每种数据类型的存储是一个有限宽度，比如 JavaScript
  使用 64 位存储数字类型，因此超出的会舍去。舍去的部分就是精度丢失的部分。
*
* ** method **
*  add / subtract / multiply /divide
*
* ** explame **
*  0.1 + 0.2 == 0.30000000000000004 （多了 0.00000000000004）
*  0.2 + 0.4 == 0.6000000000000001  （多了 0.0000000000001）
*  19.9 * 100 == 1989.9999999999998 （少了 0.0000000000002）
*
* floatObj.add(0.1, 0.2) === 0.3
* floatObj.multiply(19.9, 100) === 1990
*
*/
export const floatObj = function () {

    /*
     * 判断obj是否为一个整数 整数取整后还是等于自己。利用这个特性来判断是否是整数
     */
    function isInteger(obj) {
        // 或者使用 Number.isInteger()
        return Math.floor(obj) === obj
    }
    /*
     * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
     * @param floatNum {number} 小数
     * @return {object}
     *   {times:100, num: 314}
     */
    function toInteger(floatNum) {
        // 初始化数字与精度 times精度倍数  num转化后的整数
        var ret = { times: 1, num: 0 }
        var isNegative = floatNum < 0  //是否是小数
        if (isInteger(floatNum)) {  // 是否是整数
            ret.num = floatNum
            return ret  //是整数直接返回
        }
        var strfi = floatNum + ''  // 转换为字符串
        var dotPos = strfi.indexOf('.')
        var len = strfi.substr(dotPos + 1).length // 拿到小数点之后的位数
        var times = Math.pow(10, len)  // 精度倍数
        /* 为什么加0.5?
            前面讲过乘法也会出现精度问题
            假设传入0.16344556此时倍数为100000000
            Math.abs(0.16344556) * 100000000=0.16344556*10000000=1634455.5999999999 
            少了0.0000000001
            加上0.5 0.16344556*10000000+0.5=1634456.0999999999 parseInt之后乘法的精度问题得以矫正
        */
        var intNum = parseInt(Math.abs(floatNum) * times + 0.5, 10)
        // debugger
        ret.times = times
        if (isNegative) {
            intNum = -intNum
        }
        ret.num = intNum
        return ret
    }

    /*
     * 核心方法，实现加减乘除运算，确保不丢失精度
     * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
     * @param a {number} 运算数1
     * @param b {number} 运算数2
     */
    function operation(a, b, op) {
        var o1 = toInteger(a)
        var o2 = toInteger(b)
        var n1 = o1.num  // 3.25+3.153
        var n2 = o2.num
        var t1 = o1.times
        var t2 = o2.times
        var max = t1 > t2 ? t1 : t2
        var result = null
        switch (op) {
            // 加减需要根据倍数关系来处理
            case 'add':
                if (t1 === t2) { // 两个小数倍数相同
                    result = n1 + n2
                } else if (t1 > t2) {
                    // o1 小数位 大于 o2
                    result = n1 + n2 * (t1 / t2)
                } else {  // o1小数位小于 o2
                    result = n1 * (t2 / t1) + n2
                }
                return result / max
            case 'subtract':
                if (t1 === t2) {
                    result = n1 - n2
                } else if (t1 > t2) {
                    result = n1 - n2 * (t1 / t2)
                } else {
                    result = n1 * (t2 / t1) - n2
                }
                return result / max
            case 'multiply':
                // 325*3153/(100*1000) 扩大100倍 ==>缩小100倍
                result = (n1 * n2) / (t1 * t2)
                return result
            case 'divide':
                // (325/3153)*(1000/100)  缩小100倍 ==>扩大100倍
                result = (n1 / n2) * (t2 / t1)
                return result
        }
    }

    // 加减乘除的四个接口
    function add(a, b) {
        return operation(a, b, 'add')
    }
    function subtract(a, b) {
        return operation(a, b, 'subtract')
    }
    function multiply(a, b) {
        return operation(a, b, 'multiply')
    }
    function divide(a, b) {
        return operation(a, b, 'divide')
    }
    return {
        add: add,
        subtract: subtract,
        multiply: multiply,
        divide: divide
    }
}();

